








































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { IUnreadNotification, UnreadNoti } from '@/models/Notification'
import UnreadNotification from '@/components/UnreadNotification.vue'
import NotificationService from '@/services/NotificationService'
import GroupService from '@/services/GroupService'
import { getUnreadCount } from '@/helpers/GetNotificationCount'
import i18n from '@/lang'
import { unreadNotificationRef } from '@/firebase'

import Notification from '@/store/modules/Notification'
import store from '@/store'
import { getModule } from 'vuex-module-decorators'

const NotificationModule = getModule(Notification, store)

@Component({
  components: {
    UnreadNotification
  }
})
export default class UnReadList extends Vue {
  private groupsUserJoin: any[] = []
  private groupFilterId: any = null
  private groupFilterName: string = ''
  private notifications: IUnreadNotification[] = []
  private page: number = 1
  private showLoadMore: boolean = false
  private showLoadMoreByGroup: boolean = false
  private isLoading: boolean = false
  private isLoadingLoadMore: boolean = false
  private loadmoreAfterMarkReadNotifications: boolean = false
  private unreadCount: number = this.$store.state.notification.unreadCount
  private optionSelectAllGroup: any = {
    id: null,
    name: i18n.t('notification.all_group')
  }
  private unsubscribe: any

  async created() {
    this.getAllGroupUserJoin()
    this.getNotificationUnread()
    getUnreadCount()

    // firebase get change unread notification
    this.handleRealtime()
  }

  destroyed() {
    this.unsubscribe()
  }

  @Watch('$store.state.userInfo.user.info.id')
  handleRealtime() {
    let trackingRealtime = true
    const userId = this.$store.state.userInfo.user.info.id
    if (!userId) return
    const start = this.moment()
      .subtract(1, 'm')
      .toDate()
    this.unsubscribe = unreadNotificationRef
      .where('userIds', 'array-contains', userId)
      .where('createdAt', '>', start)
      .onSnapshot((querySnapshot: any) => {
        if (trackingRealtime) {
          trackingRealtime = false
        } else {
          this.getNotificationUnread()
          getUnreadCount()
        }
      })
  }

  async getAllGroupUserJoin() {
    await GroupService.getAllGroupCompact().then(res => {
      if (res.status === 200) {
        this.groupsUserJoin = res.data.data
      }
    })

    this.groupsUserJoin.unshift(this.optionSelectAllGroup)
    this.groupFilterId = this.optionSelectAllGroup.id
    this.groupFilterName = this.optionSelectAllGroup.name
  }

  selectGroupFilter(index: number) {
    let selectedGroupFilter: any = this.groupsUserJoin[index]
    this.groupFilterId = selectedGroupFilter.id
    this.groupFilterName = selectedGroupFilter.name
  }

  /**
   * getNotificationUnreadCommon
   */
  async getNotificationUnreadCommon(group_id?: number) {
    this.isLoading = this.page > 1 ? false : true
    this.isLoadingLoadMore = this.page > 1 ? true : false
    let limit = this.$store.state.setting.setting.setting_display
      .number_item_lists

    await NotificationService.getNotificationUnread(limit, this.page, group_id)
      .then(async res => {
        if (res.status === 200) {
          this.showLoadMore =
            this.page < res.data.meta.pagination.total_pages ? true : false

          const data = res.data.data.map((item: any) => new UnreadNoti(item))

          if (this.loadmoreAfterMarkReadNotifications === true) {
            this.notifications = []
            this.notifications = [...this.notifications, ...data]
          } else {
            this.notifications = [...this.notifications, ...data]
          }
        }
      })
      .catch(err => {
        console.log(err)
      })

    this.isLoading = false
    this.isLoadingLoadMore = false
  }

  /**
   * getNotificationUnread
   */
  getNotificationUnread() {
    this.loadmoreAfterMarkReadNotifications = true
    this.getNotificationUnreadCommon()
  }

  /**
   * getNotificationUnreadByGroup
   */
  async getNotificationUnreadByGroup() {
    this.page = 1
    this.notifications = []
    this.loadmoreAfterMarkReadNotifications = false
    if (this.groupFilterId) {
      await this.getNotificationUnreadCommon(this.groupFilterId)
      NotificationService.getNotificationCountByGroup(this.groupFilterId)
        .then(res => {
          if (res.status === 200) {
            let unreadCount = res.data
            this.unreadCount = unreadCount
          }
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      await this.getNotificationUnreadCommon()
      this.unreadCount = this.$store.state.notification.unreadCount
    }

    this.showLoadMoreByGroup = true
    ;(this.$refs.unreadNotification as any).isCheckAll = false
  }

  /**
   * loadMoreNotification
   */
  async loadMoreNotification() {
    this.page++
    if (this.loadmoreAfterMarkReadNotifications === true) {
      this.page = 1
    }
    await this.getNotificationUnreadCommon(
      this.groupFilterId && this.showLoadMoreByGroup ? this.groupFilterId : null
    )
    this.loadmoreAfterMarkReadNotifications = false
    ;(this.$refs.unreadNotification as any).isCheckAll = false
  }

  /**
   * markUnreadNotificationIds
   */
  async markUnreadNotificationIds(notificationIds: any) {
    this.$blockui.show()
    await NotificationService.markReadedNotification(notificationIds)
      .then(async res => {
        if (res.status === 200) {
          notificationIds.notifications.forEach(
            (element: any) =>
              (this.notifications = this.notifications.filter(
                item => item.id !== element
              ))
          )

          await getUnreadCount()
          NotificationModule.CHANGE_NOTIFICATION_UNREAD()

          if (this.groupFilterId != null) {
            NotificationService.getNotificationCountByGroup(this.groupFilterId)
              .then(res => {
                if (res.status === 200) {
                  let unreadCount = res.data
                  this.unreadCount = unreadCount
                }
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            this.unreadCount = this.$store.state.notification.unreadCount
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => this.$blockui.hide())

    this.loadmoreAfterMarkReadNotifications = true
  }
}
